import { usePage, router } from "@inertiajs/vue3";
import { loadLanguageAsync, getActiveLanguage, trans } from 'laravel-vue-i18n';
import { ref } from "vue";

export const currentLocale: any = ref(null);

// Change Current Locale
export const locale = (lang: string): void => {
    router.post(route('locale.index'), {
        locale: lang,
    }, {
        onSuccess: async () => {
            await loadLanguageAsync(usePage().props.locale as string);
            document.querySelector('body')!.style.direction = trans("direction");
            currentLocale.value = usePage().props.locale;
        }
    })
}
